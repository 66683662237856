#entry {
  // height: max-content;
  height: 100dvh;
  // min-height: 133%;
  // padding-top: 15px;
  padding-bottom: 50px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  h1 {
    // text-transform: capitalize;
    font-style: italic;
    // font-size: calc(min(7vh, 15vw));
    margin-top: 0;
    // white-space: nowrap;
    // text-decoration: underline;

    text-shadow: -2px 0px var(--color-bg), 2px 0px var(--color-bg), 0px -2px var(--color-bg), 0px 2px var(--color-bg),
      -2px -2px var(--color-bg), -2px 2px var(--color-bg), 2px -2px var(--color-bg), 2px 2px var(--color-bg);

    display: inline;

    box-shadow: inset 0 -0.205em var(--color-bg), inset 0 -0.25em var(--color-text);

    align-self: flex-start;
    transition: 0.5s, background-color 0s, box-shadow 0s;
  }

  h2 {
    transition: 0.5s, transform 0.25s;
    font-style: italic;
    transform-origin: left bottom;
    margin: 0;
  }

  h3 {
    margin-bottom: 0;
  }

  h4 {
    margin: 0;
    font-style: italic;
    font-size: 1.25rem;
  }

  .header {
    margin-left: calc(max(1em, 5vw));
    h1 {
      position: relative;
      font-size: inherit;

      .name {
        line-height: 0.8em;
        display: block;
        margin: 0.333em 0;
      }
    }
    margin-top: 0.25em;
    margin-bottom: 0.1em;
    margin-right: 55px;

    .arrow {
      text-decoration: none;
      color: var(--color-text);
      position: absolute;
      left: -1.1em;
      top: 0.4em;
      font-size: 0.7em;
    }
  }

  .body {
    padding-left: 5vw;
    padding-right: 5vw;

    @media screen and (max-device-aspect-ratio: 1/1) {
      padding-left: 10px;
      padding-right: 10px;
    }

    display: flex;
    flex: 1;
    // height: calc(100vh - 100px - 7vh);
    // width: 100%;
    gap: 5px;
    min-height: 0;

    @media screen and (max-device-aspect-ratio: 1/1) {
      flex-direction: column;
    }

    .panel {
      min-width: 0;
      // min-height: 0;

      transition: 0.333s flex;

      overflow: hidden;
      position: relative;

      .panel-toggle {
        & > div {
          transition: left 0.25s, top 0.25s, transform 0.1s;
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          position: absolute;
        }
        .rot {
          transition: transform 0.25s;
        }

        color: var(--color-accent);
        cursor: pointer;
        font-family: fantasy;
        vertical-align: bottom;
        font-size: 1.5em;
        position: absolute;
        z-index: 1;

        &.vertical {
          // right: 0;
          height: 100%;
          width: 25px;
          & > div {
            top: calc(50% - 12.5px);
          }
        }
        &.horizontal {
          width: 100%;
          height: 25px;
          & > div {
            left: calc(50% - 12.5px - 9px); //9px = scroll bar
          }
        }
        @media screen and (max-device-aspect-ratio: 1/1) {
          &.vertical {
            width: 100%;
            height: 25px;
            & > div {
              left: calc(50% - 12.5px - 9px); //9px = scroll bar
            }
          }
        }

        &.arrow {
          top: -5px;
        }
      }

      .panel-scroll {
        transition: padding 0.333s;

        box-sizing: border-box;
        width: 100%;
        height: 100%;

        position: relative;
        .panel-body {
          transition: opacity 0.5s;
        }
      }

      .panel-footer {
        &.open {
          // border-top: 1px solid var(--color-accent);
          background-color: var(--color-bg);
          align-items: stretch;
        }

        // transition: opacity 0.25s, width 0.25s;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 15px 25px 15px 15px;

        @media screen and (max-device-aspect-ratio: 1/1) {
          padding: 10px;
        }

        // padding-right: 25px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }

      &:not(.openIcon) {
        cursor: pointer;
      }
      &:not(.open) {
        flex: 0 1 25px;

        &.comments {
          h2 {
            @media screen and (min-device-aspect-ratio: 1/1) {
              transform: rotate(90deg) translate(-1em, 1px);
            }
          }
        }
        h2 {
          font-size: 14px;
          font-weight: normal;
          // margin-left: 20px;
          color: var(--color-accent);
        }
        .panel-scroll {
          // overflow-y: hidden;
          // overflow-x: hidden;
          padding: 5px;
          .panel-body {
            opacity: 0;
          }
        }
        .panel-footer {
          opacity: 0;
        }
      }
      &.open {
        .panel-scroll {
          overflow-y: auto;
          // overflow-y: auto;
          overflow-x: hidden;

          padding: 5vmin;
          padding-bottom: 0;

          @media screen and (max-device-aspect-ratio: 1/1) {
            padding-left: 25px;
            padding-right: 25px;
          }
        }

        flex: 1;

        &.comments {
          .panel-scroll {
            padding: 25px;
            padding-bottom: 0;
          }
        }
      }
      &.openIcon {
        .panel-toggle {
          // justify-content: flex-start;
          // align-items: flex-start;

          // width: 25px;
          // height: 25px;
          &.vertical {
            & > div {
              top: 0;
            }
          }
          &.horizontal {
            & > div {
              left: 0;
            }
          }

          @media screen and (max-device-aspect-ratio: 1/1) {
            &.vertical {
              & > div {
                left: 0;
              }
            }
          }

          .rot {
            transform: rotate(45deg);
          }
        }
      }
      border: 1px solid var(--color-accent);
    }

    .left {
      min-width: 200px;
      min-height: 60px;
      // max-width: 33vw;
      flex: 1.5;
      @media screen and (max-device-aspect-ratio: 1/1) {
        flex: 2;
      }
      transition: flex 0.333s;

      display: flex;
      flex-direction: column;
      gap: 5px;

      .resources {
        .panel-scroll {
          display: flex;
          flex-direction: column;
          padding-bottom: 3em;

          .panel-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;

            .resources-container {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-items: center;
            }
          }
        }
        .panel-footer {
          .add-resource {
            // max-width: 50%;
            display: flex;
            gap: 15px;
            input[placeholder="descriptor"] {
              // max-width: 100px;
            }
          }
        }
      }

      .images {
        h2 {
          margin-bottom: 15px;
        }
        .panel-scroll {
          padding-bottom: 0;
        }
        .panel-body {
          margin-left: calc(-5vmin + 15px);
          margin-right: calc(-5vmin + 15px);

          @media screen and (max-device-aspect-ratio: 1/1) {
            margin-left: -20px;
            margin-right: -20px;
          }

          .columns-container {
            width: 100%;
            display: flex;
            gap: 15px;
            .column {
              align-items: center;
              min-width: 0;
              flex: 1;
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: column;
              // align-items: flex-start;
              gap: 15px;

              img {
                max-width: 100%;
                display: block;
              }
            }
          }
        }
        .panel-footer {
          transition: max-height 0.5s;
          height: 100%;
          max-height: 60px;
          &.open.compressed-image {
            max-height: 100vh;
          }

          .upload-image {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-end;
            gap: 15px;

            .compressed-image-container {
              align-self: center;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-height: 100%;
                max-width: 100%;
              }
              // max-width: 50vh;
              flex: 1;
              min-height: 0;
              min-width: 0;
            }
            .inputs {
              gap: 15px;
              // flex: 1;
              display: flex;
              align-items: center;

              .compressing {
                font-family: monospace;
              }
            }

            input {
              flex: 1;
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
    }
  }

  &:not(.resourcesOpen):not(.imagesOpen) {
    .left {
      flex: 0;
    }
  }
}

#resource {
  // min-width: 200px;
  display: block;
  color: inherit;
  text-decoration: inherit;

  margin-left: 3em;
  position: relative;

  max-width: 100%;
  @media screen and (min-device-aspect-ratio: 1/1) {
    margin: 2em 2em 0 2em;
    max-width: calc(min(100% - 10vmin, 500px));
  }

  @media screen and (max-device-aspect-ratio: 1/1) {
    margin: 2em 0 1em 0;
    margin-top: 3em;
  }

  .star {
    position: absolute;
    left: -1.2em;
    font-size: 2em;
    top: 0.5em;
    transform: translateY(-50%);

    @media screen and (max-device-aspect-ratio: 1/1) {
      left: 0;
      top: -0.75em;
    }
  }
  .resource-container {
    border: 1px solid var(--color-accent);
    width: 100%;

    .resource-body {
      overflow: hidden;
      text-overflow: ellipsis;

      // max-height: 8em;
      // width: 100%;
      padding: 5px;
      display: flex;
      gap: 10px;

      @media screen and (max-device-aspect-ratio: 1/1) {
        flex-direction: column;
        height: auto !important;
      }
      // height: 100px;
      .resource-text {
        padding-right: 5px;
        min-width: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .resource-name {
          // font-size: 2em;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        .resource-description {
        }
      }
      .resource-image-container {
        flex: 0.5;

        img {
          max-height: 25vh;
          width: 100%;
          height: 100%;
          min-height: 0;
          min-width: 0;
          object-fit: cover;
          // height: auto;
        }
      }
    }
    .resource-footer {
      border-top: 1px solid var(--color-accent);
      padding: 2px;
      .url {
        max-width: 100%;

        .url-icon {
          margin-right: 3px;
          margin-left: 1px;
        }
      }
    }
  }
}

#comment {
  margin-top: 3em;
  padding-left: 12px;

  // margin-left: -12px;
  border-left: 1px solid var(--color-text);
  // &:hover {
  //   // border-width: 2px;
  //   // margin-left: -1px;
  //   // border-color: var(--color-accent);
  //   // .comment-header::before {
  //   //   color: var(--color-accent);
  //   // }
  // }

  .comment-hover {
    &:hover,
    &:focus {
      .comment-header::before {
        // font-weight: bolder;
        // color: var(--color-accent);
        // margin-left: -1px;
        transform: rotate(45deg);
      }
      .comment-body {
        .comment-footer,
        .comment-footer button {
          opacity: 1;
        }
      }
    }

    .comment-header {
      white-space: nowrap;
      transform: translateY(-1.25em);
      position: relative;
      height: 2em;
      &::before {
        transition: transform 0.05s;
        position: absolute;
        left: -1.2em;
        content: "↣";
      }
    }

    .comment-body {
      margin-top: -1.75em;

      padding-left: 0.25em;
      // font-size: 1.2rem;
      margin-bottom: 2.5em;
      .text {
        // font-family: monospace;
        white-space: pre-wrap;
      }
      position: relative;
      .comment-footer {
        height: 1.25em;
        bottom: -1.25em;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 0.25em);
        padding-right: 0.5em;
        box-sizing: border-box;

        // opacity: 0;
        button {
          opacity: var(--opacity-inactive);
          color: var(--color-accent);
          &:hover,
          &:focus {
            opacity: 1;
          }
        }
        &:hover {
          opacity: 1;
          button {
            opacity: 1;
          }
        }
      }
    }
  }
}

// #comment .comment-body:hover {
//   .write-reply-button {
//     color: var(--color-accent);
//   }
// }

#reply {
  .write-reply {
    margin-top: 15px;
    font-family: monospace;

    .write-reply-body {
      margin-top: 8px;

      width: 100%;
      display: flex;
      align-items: flex-end;
      padding-bottom: 0px;

      button {
        margin-right: -15px;
      }
    }

    .replies {
      margin-left: 15px;
    }
  }
}

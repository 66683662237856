// body {
// }

#homepage {
  overflow-x: hidden;
  overflow-y: scroll;

  max-width: 100vw;
  max-height: 100vh;
  position: relative;

  min-height: 100vh;

  // height: max-content;
  // min-height: 133%;
  // padding-top: 50px;
  padding-bottom: 25%;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  h1 {
    user-select: none;

    font-style: italic;
    font-size: max(50px, 8vw);
    // white-space: nowrap;
    // text-decoration: underline;

    text-shadow: -2px 0px var(--color-bg), 2px 0px var(--color-bg), 0px -2px var(--color-bg), 0px 2px var(--color-bg),
      -2px -2px var(--color-bg), -2px 2px var(--color-bg), 2px -2px var(--color-bg), 2px 2px var(--color-bg);

    display: inline;

    box-shadow: inset 0 -0.205em var(--color-bg), inset 0 -0.25em var(--color-text);

    align-self: flex-start;
    transition: 0.5s, background-color 0s, box-shadow 0s;
  }

  h2 {
    user-select: none;
    // font-style: italic;
    font-size: 50px;
    margin: 0;
    text-transform: capitalize;
    transition: 0.5s, background-color 0s;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    transition: font-size 0.5s;
    min-height: 0;

    a {
      color: inherit;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    // margin-left: 10px;
    transition: 0.5s, background-color 0s;
  }

  h5 {
    // font-variant: small-caps;
    // font-style: italic;
    // font-family: var(--font-mono;
    font-weight: 500;
    margin: 0;
    font-size: 20px;
    margin-bottom: 5px;
    transition: 0.5s, background-color 0s;
  }

  .header {
    margin-top: 2em;

    padding: 0 max(60px, 10vmin);

    .settings-bar {
      margin-top: 25px;
      display: flex;

      .search-bar {
        // all: unset;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
        line-height: 1.5em;

        background-color: transparent;
        display: block;
        border: 0;
        margin-bottom: 50px;
        border-bottom: 1px solid var(--color-text);
        padding: 5px;
        color: var(--color-text);
        max-width: 25vw;
        &::placeholder {
          font-size: 16px;
          font-family: "Times New Roman", Times, serif;
          color: var(--color-text);
          font-style: italic;
        }
      }
      .mode-select {
        margin-left: 50px;

        button {
          // display: inline-block;
          border: 0;
          padding: 0;
          height: auto;
          display: block;
          cursor: pointer;
          white-space: nowrap;
        }

        .selected {
          text-decoration: underline;
          position: relative;
          &::before {
            top: -2px;
            left: -1em;
            position: absolute;
            text-decoration: none;
            content: "✦";
          }
        }
        :not(.selected) {
          font-style: italic;
        }
      }
    }
  }

  &.mode-image {
    .entries {
      padding: 0 15px;
      gap: 15px;
    }
    .entries .column .category {
      margin-bottom: 0;
    }
  }

  .entries {
    transition: padding 0.5s;
    padding: 0 calc(max(60px, 10vmin));

    flex: 1;

    display: grid;
    gap: 60px;

    .column {
      .category {
        margin-bottom: 25px;
      }

      .entry.edit-entry.open {
        .images {
          overflow-x: auto;
          overflow-y: hidden;
        }

        .title-container {
          display: flex;
          align-items: center;
          h3 {
            flex: 1;
          }
          .active {
            margin-left: 15px;
            font-size: 3em;
          }
        }

        .trailing {
          transform: translateY(0.5em);
          display: flex !important;
          align-items: center;
          form {
            flex: 1;
            width: 0;
            display: inline-flex;
            align-items: center;

            max-width: 75%;
            margin-left: 10px;
            margin-left: auto;

            input {
              margin: 15px;
              // direction: rtl;
              border: 1px solid var(--color-text);
              border-top: 0;
              border-left: 0;
              padding: 3px;
            }
            button {
              font-size: 14px;
              height: 2em;
            }
          }
        }

        &.removed {
          .delete {
            margin-top: 10px;
          }
          .submit-container {
            margin-top: 10px;
          }
        }
        margin-bottom: 80px;
        .submit-container {
          // border-left: 1px solid var(--color-text);
          padding-left: 15px;
          margin-left: -15px;

          display: flex;
          align-items: center;
          margin-top: 40px;

          .submit-entry {
            margin-right: 15px;
          }

          position: relative;
          &::before {
            position: absolute;
            left: -0.3em;
            // top: 50%;
            // transform: rotate(-90deg);
            // font-size: 1.5em;
            content: "➥";
          }
        }
      }

      .entry {
        transition: height 1s, margin 1s, padding 1s;
        padding-bottom: 5px;
        width: 100%;
        &:not(.mode-text).has-preview-image:not(.open) {
          padding-bottom: 15px;
        }
        
        // margin-left: 10px;

        .name {
          button {
            user-select: auto;
            font-style: normal;
            border: 0;
            padding: 0;
            height: auto;
          }
        }
        &:not(.open) {
          .entry-header:hover {
            .name {
              text-decoration: underline;
            }
          }
          .entry-header:hover, .entry-header:focus-within {
            // .name {
            //   text-decoration: underline;
            //   .link {
            //     pointer-events: none;
            //   }
            // }
            cursor: pointer;
            & h3:after {
              font-size: 1rem;
              position: absolute;
              right: 0;
              content: " ↩";
            }
          }
       }

        .entry-header {
          min-height: 0;
          transition: min-height 1s, margin 1s;

          position: relative;

          .title-container {
            transition: height 0.15s;
            
            h3 {
              position: relative;
            }
            .name button {
              text-align: left;
            }
          }

          .preview-image {
            opacity: var(--opacity-inactive);
            // opacity: calc(var(--opacity-inactive) * 1.5);

            margin-top: 8px;
            width: calc(100% - 30px);
            // width: 100%;
            height: 10vh;
            object-fit: cover;

            border-left: 1px solid var(--color-text);
            padding-left: 15px;

            position: relative;
            z-index: 1;
            transition: 1s;
          }

          &:hover, &:focus-within {
            .preview-image {
              opacity: 1;
              transition: 1s, opacity 0.333s;
            }
          }
        }

        .body {
          pointer-events: none;
          margin-top: 10px;

          width: 100%;
          opacity: 0;
          transition: height 1s, opacity 1s, padding 0.5s, visibility 1s;
          box-sizing: border-box;

          .resource-li {
            padding-left: 10px;
          }
          .edit-link {
            display: flex;
          }
          .link {
            position: relative;

            &:not(.edit-link) {
              padding-left: 10px;
              box-sizing: border-box;
            }
            .active {
              padding-left: 10px;
              padding-right: 10px;
            }
            .star {
              position: absolute;
              left: -10px;
              top: 0px;
              font-size: 1em;
            }

            width: 100%;
            // margin-bottom: 5px;
            // h4 {
            // transition: 0.5s, color 0s, background-color 0s;
            // & > * {
            background-color: var(--color-bg);
            // }

            // white-space: nowrap;
            background-color: var(--color-bg);
            // display: inline-flex;
            // align-items: flex-end;

            input[placeholder="descriptor"] {
              max-width: 100px;
            }

            h4 {
              max-width: 100%;
              display: inline;
              // display: flex;
              // align-items: flex-end;
              // .url {
              //   margin-bottom: 0.5px;
              // }
              margin-right: 5px;
            }
            .url-icon {
              // margin-bottom: 0.5px;
              height: 1em;
              // vertical-align: top;
            }
            .char {
              text-decoration: none;
            }
            .url-input {
              flex: 1;
            }
            // }
          }

          .resources-container,
          .images-container,
          .comments-container {
            border-left: 1px solid var(--color-text);
            padding-left: 15px;
            margin-left: -15px;
            padding-top: 1em;

            &:not(:first-child) {
              margin-top: 35px;
            }

            ul {
              min-height: 1px;
              margin-top: 0;
              margin-bottom: 0;
              padding: 0;
              position: relative;
              li:not(:last-child) {
                margin-bottom: 5px;
              }

              max-width: 100%;
              // width: min-content;
              list-style-type: none;
            }

            // margin-bottom: 30px;
            .trailing {
              display: block;
              height: 0;
              margin-top: 1em;

              & > * {
                // display: inline-block;
                // height: 0;
                // vertical-align: bottom;
              }
              .active-link {
                margin-bottom: -2px;
                .active {
                  padding-left: 10px;
                  padding-right: 10px;
                }
              }

              .url {
                // margin-bottom: -15px;
                position: relative;
                overflow: visible;

                &::before {
                  color: var(--color-text);
                  position: absolute;
                  top: 10px;
                  left: -15px;
                  font-size: 1.25em;
                  transform: translate(-40%, -60%); // rotate(180deg);
                  content: "☞";
                  transition: transform 0.15s;
                  // content: "↶"
                }
                &:hover,
                &:focus {
                  &::before {
                    transform: translate(-40%, -50%) rotate(90deg);
                  }
                }
              }
            }
          }

          .divider {
            margin: auto;
            margin-top: 25px;
            height: 1px;
            width: 66%;
            background-color: var(--color-text);
          }
        }

        .images {
          display: flex;
          align-items: flex-end;
          gap: 10px;
          width: 100%;

          .image-container {
            display: flex;
            flex-direction: column;
            // align-items: flex-start;
            // overflow-x: auto;
            max-width: calc(100% / 4 - 8px);

            flex: 1;
            img {
              // flex: 1;
            }
          }
        }

        .comments {
          width: 100%;
          // margin: auto;
          // text-align: right;
          .comment {
            max-width: 100%;
            max-height: 4.5em;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-bottom: 0.5em;
          }
        }

        &.mode-text {
          .body {
            margin-top: 27px;
          }
        }
        &.mode-image {
          h3 {
            font-size: 0;
          }
        }
        &:not(.mode-image) {
          .entry-header {
            padding-left: 1em;
            margin-left: -1em;
          }
        }
        &:not(.open) {
          &.mode-image {
            h3 {
              visibility: hidden;
            }
            .preview-image {
              margin-top: 0;
            }
          }
          &:not(.mode-image) {
            h3.starred::before {
              top: -1px;
              left: -1em;
              position: absolute;
              content: "✦";
              transition: none !important;
            }
          }

          .body {
            visibility: hidden;
          }
        }

        .arrow {
          top: 0.325em;
        }

        .star {
          top: calc(1em + 0.325em);
        }
        .edit {
          top: calc(2em + 0.325em);
          div {
            font-size: 0.75em;
          }
        }

        .arrow,
        .edit,
        .star {
          font-size: 0.75em;
          left: -45px;
          position: absolute;

          height: 1em;
          display: flex;
          align-items: center;

          div {
            pointer-events: none;
            width: min-content;
          }
        }

        &.open {
          &:not(:first-child) {
            margin-top: 25px;
          }
          margin-bottom: 60px;

          transition: height 0.1s;

          z-index: 1;
          // height: 300px;

          &:not(.mode-image) .entry-header {
            h3 button {
              // margin-left: 10px;
            }
          }
          .entry-header {
            // min-height: 75px;
            transition: min-height 0.25s, margin 0.25s;
          }

          h3 {
            font-size: 3em;
            .name {
              text-decoration: underline;

              position: relative;
              .link::after {
                position: absolute;
                display: inline-block;
                right: -1.25em;
                content: "☞";
                transform: rotate(0deg) scale(0, 0);
                transition: transform 0.15s;
              }
              .link:hover,
              .link:focus {
                &::after {
                  transform: rotate(90deg) scale(1, 1);
                }
              }
            }
            position: relative;
          }
          .body {
            pointer-events: inherit;
            opacity: 1;
          }
          .preview-image {
            opacity: 1;
            margin-top: 15px;
            padding-left: 0px;

            // width: calc(100% + 25px);
            width: 100%;

            // border-left: none;
            border-left-color: transparent;
            height: 33vh;
            // transition: 5s;
            // box-shadow: -1em -0.75em 1em var(--color-bg),
            //  inset 1em 1em 1em var(--color-bg);
            transition: 0.25s;
          }
        }

        &.mode-image {
          &:not(.edit-entry) h3 {
            display: flex;
            align-items: center;

            .arrow,
            .star,
            .edit {
              position: relative;
              left: 0;
              top: 0;
              min-width: 40px;
            }
          }

          width: 100%;
          .body {
            padding-left: 30px;
          }
          .preview-image {
            width: 100%;
            opacity: 1;
            height: auto;
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}
